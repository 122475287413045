<template>
    <!--    展览团队成员-->
    <div>
        <div class="plateBox srhBox flex_l_c">
            <input type="text" class="mainBdColor srhIpt" placeholder="请输入搜索关键词" v-model="srhStr">
            <button class="srhBtn mainBdColor mainBgColor" @click="search"><i class="el-icon-search"></i>搜索</button>
        </div>
        <div class="plateBox">
            <div class="plateTit"><span class="plateTitIcon mainBgColor"></span><span>筛选结果/操作</span></div>
            <el-table
                    :data="list"
                    v-loading="loading"
                    class="table" ref="tableBox">
                <el-table-column prop="serialNumber" label="序号" width="110" sortable></el-table-column>
                <el-table-column prop="exhibitionName" label="展览名称" width="120" sortable show-overflow-tooltip/>
                <el-table-column prop="exhibitionTeamName" label="展览组织" width="120" sortable show-overflow-tooltip/>
                <el-table-column prop="name" label="姓名(名称）" width="120" sortable show-overflow-tooltip/>
                <el-table-column prop="gender" label="性别" sortable show-overflow-tooltip/>
                <el-table-column prop="dateOfBirth" label="出生日期" width="120" :formatter="formatterBirth" sortable show-overflow-tooltip/>
                <el-table-column prop="position" label="职务" sortable show-overflow-tooltip/>
                <el-table-column prop="theTitle" label="职称" sortable show-overflow-tooltip/>
                <el-table-column prop="professional" label="专业" sortable show-overflow-tooltip/>
                <el-table-column prop="specialty" label="专长" sortable show-overflow-tooltip/>
                <el-table-column prop="national" label="民族" sortable show-overflow-tooltip/>
                <el-table-column prop="educationBackground" label="学历" sortable show-overflow-tooltip/>
                <el-table-column prop="phone" label="联系电话" width="120" sortable show-overflow-tooltip/>
                <el-table-column prop="contribution" label="承担的工作（贡献）" width="180" sortable show-overflow-tooltip/>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, prev, pager, next, sizes">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['homeSrh'],
        data() {
            return {
                srhStr: '',
                list: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading: false,
            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                return this.$store.state.config.defaultTheme
            },
        },
        watch: {
            defaultTheme() {
                this.changeMainColor(this.defaultTheme);
            },
            homeSrh(val) {
                this.srhStr = val;
                this.tableData();
            },
            srhStr(val) {
                this.pageNo = 1
                this.$emit('changeSrh', val)
            },
        },
        created() {
            this.srhStr = this.homeSrh;
        },
        mounted() {
            this.search();
            this.$nextTick(() => {
                this.changeMainColor(this.defaultTheme);
            })
        },
        methods: {
            formatterBirth(row) {
                if(row.dateOfBirth) {
                    let date = row.dateOfBirth.split('-')
                    return date[0] + '年' + date[1] + '月'
                }
            },
            // 搜索
            search() {
                this.pageNo = 1
                this.tableData()
            },
            tableData() {
                this.loading = true
                this.$axios(this.api.common.TeamMemberList, {
                    "name": this.srhStr,
                    'current': this.pageNo,
                    'size': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.list = data.data.records
                        this.list.forEach(f=>{
                            if(f.gender=="0"){
                                f.gender ="男";
                            }else if(f.gender=="1"){
                                f.gender ="女"
                            }
                        })
                        this.total = parseInt(data.data.total)
                        this.loading = false
                        this.$refs.tableBox.doLayout();
                    } else {
                        this.$message.error("查询失败")
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.tableData();
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.tableData();
            },
        }
    }
</script>

<style scoped>

</style>
